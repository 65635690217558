@charset "UTF-8";

/** -----------------------------------------------------------------------------------------

        *  Автор: Заур Магомедов
        *  Автор URL: https://zaurmag.ru
        *  E-mail: order@zaurmag.ru
        *  Skype: zaur-mag11

 ---------------------------------------------------------------------------------------- **/

// ****************** Base ******************
html {
    height: 100%; }

body {
    background-color: $white;
    font-family: $font-family-base;
    height: 100%; }

#page {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    text-align: left;
    min-width: 320px;
    max-width: 1920px;
    transition: margin .2s ease;
    position: relative;
    justify-content: center;
    height: 100%; }


// ****************** Form container ******************
.form-container {
    margin: 0 auto 50px;
    width: 450px; }

.form {
    &__form {
        $formForm: & !global;
        position: relative;

        &::before {
            background: rgba($white, .5);
            content: "";
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 5; }

        textarea {
            height: 150px; } } }

.btn {
    font-size: 14px;
    text-transform: uppercase;

    /** === Form messages === * */
    &__error {
        color: $red;
        text-align: center;
        padding-top: 8px;
        margin-top: 15px;
        font-size: 14px; }

    &__sys-message {
        text-align: center; }

    &__success-title {
        color: $green;
        font: 700 20px Arial, sans-serif;
        margin: 0 0 15px; }

    &__success-text {
        color: #333;
        font-size: 15px;
        margin: 0; } }

/** === Sending === * */
#{$formForm} {
    &.sending {
        &::before {
            display: block; }

        // Btn
        .btn.btn-submit {
            border-color: transparent;
            animation: barberpole 0.5s linear infinite;
            background-size: 30px 30px;
            background-color: $gray-600;
            background-image: linear-gradient(45deg, rgba($dark, .5) 25%, transparent 25%, transparent 50%, rgba($dark, .5) 50%, rgba($dark, .5) 75%, transparent 75%, transparent); } } }

@-webkit-keyframes barberpole {
    from {
        background-position: 0 0; }
    to {
        background-position: 60px 30px; } }


@-moz-keyframes barberpole {
    from {
        background-position: 0 0; }
    to {
        background-position: 60px 30px; } }


@-o-keyframes barberpole {
    from {
        background-position: 0 0; }
    to {
        background-position: 60px 30px; } }


@keyframes barberpole {
    from {
        background-position: 0 0; }
    to {
        background-position: 60px 30px; } }
